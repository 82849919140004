import React, { Component } from 'react'

export default class Respon extends Component {
  render() {
    return (
        <div style={{backgroundColor:'grey',color:'white',margin:15,
        fontSize:20,fontFamily:'bold'}}>
     Gracias por su e-mail , pronto responderemos
        </div>
    )
  }
}

